import makeStyles from '@mui/styles/makeStyles';
import { emphasize } from '@mui/material/styles';

export const errorStyle = (theme) => ({
  border: `1px solid ${theme.palette.error.main} !important`,
  borderRadius: 6,
  '&:focus': {
    border: `1px solid ${theme.palette.error.main} !important`,
  },
});

export const inputStyle = (theme) => ({
  borderRadius: 6,
  position: 'relative',
  backgroundColor: theme.palette.secondary.light1,
  border: '1px solid transparent !important',
  fontSize: '14px',
  minHeight: '40px',
  padding: theme.spacing(1.75),
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  '&:not(:placeholder-shown)': {
    color: theme.palette.grey.black,
  },
  '&:placeholder': {
    color: theme.palette.grey[600],
  },
  '&:hover': {
    border: `1px solid ${theme.palette.primary.light3} !important`,
  },
  '&:focus': {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  '&:focus-within': {
    borderColor: `${theme.palette.primary.main} !important`,
  },
});

export const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    fontSize: 14,
    color: theme.palette.grey[800],
    transform: 'none !important',
  },
  filesLabel: {
    fontSize: '.9rem',
    color: theme.palette.grey[700],
    transform: 'translateX(16px) !important',
    marginTop: theme.spacing(2),
  },
  fieldError: {
    ...errorStyle(theme),
  },
  error: {
    ...errorStyle(theme),
    border: 'none',
    fontSize: '14px',
    color: theme.palette.error.main,
    '& .MuiInput-input': {
      borderColor: theme.palette.error.main,
    },
  },
  datePicker: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-root': {
      ...inputStyle(theme),
      padding: theme.spacing(0, 2, 0, 0),
      '& input': {
        paddingTop: 10,
        paddingBottom: 10,
        lineHeight: '20px !important',
        borderRadius: 6,
        '&::placeholder': {
          color: `${theme.palette.grey[600]}`,
        },
        '&[aria-invalid="true"]': {
          color: theme.palette.grey[600],
        },
        '&[aria-invalid="false"]': {
          color: theme.palette.grey.black,
        },
      },

      '&:not(.Mui-disabled) .MuiSvgIcon-root': {
        color: theme.palette.grey[600],
      },

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
      },
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  marker: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(1),
    width: 24,
    height: 24,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[300],
    pointerEvents: 'none',
  },
  colorPicker: {
    '& .MuiInputBase-input': {
      paddingLeft: theme.spacing(5),
      backgroundColor: `${theme.palette.secondary.light1}`,
      border: '1px solid transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.light3}`,
      },
    },
  },
  select: {
    '& .Mui-focused > .MuiInput-input': {
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: theme.palette.common.white,
    },
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:active': {
      boxShadow: '20px 2px 44px rgba(0, 0, 0, 0.04)', // Todo: move to theme
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      display: 'none',
    },
  },
  selectWithLabel: {
    marginTop: theme.spacing(3),
  },
  selectWithCount: {
    '& .MuiIconButton-root': {
      marginRight: 0,
    },
  },
  box: {
    maxWidth: '100%',
    borderRadius: 6,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  attachmentButton: {
    maxWidth: '100%',
    borderRadius: 6,
    cursor: 'pointer',
    border: 'none',
    color: theme.palette.grey[600],
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light2,
    },
  },
  attachmentError: {
    color: theme.palette.error.main,
  },
  attachments: {
    width: '200px !important',
    backgroundColor: theme.palette.common.white,
  },
  loading: {
    backgroundColor: theme.palette.grey[300],
  },
  file: {
    backgroundColor: theme.palette.primary.light1,
    paddingLeft: theme.spacing(1),
    borderRadius: 6,
  },
  errorFile: {
    backgroundColor: theme.palette.error.light1,
  },
  fileName: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.grey.black,
  },
  fileIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  delete: {
    color: theme.palette.grey[600],
  },
  download: {
    color: theme.palette.grey[600],
  },
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  input: {
    display: 'flex !important',
    ...inputStyle(theme),
    padding: '0 !important',
    paddingLeft: `${theme.spacing(1)} !important`,
    height: 'auto !important',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(0, 1),
    maxHeight: 58,
    minHeight: 27,
    overflowY: 'auto',
  },
  valueCount: {
    position: 'absolute',
    right: 32,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(0.75, 2),
  },
  singleValue: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 6px)',
  },
  divider: {
    height: theme.spacing(2),
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  textarea: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  uploader: {
    '&:focus': {
      outline: 'none',
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  errorImage: {
    borderColor: `${theme.palette.error.main} !important`,
    backgroundColor: theme.palette.error.light1,
    '&:focus': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  option: {
    borderRadius: 6,
    '&.Mui-selected': {
      borderColor: `${theme.palette.common.white} !important`,
      borderRadius: 6,
    },
  },
  optionText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
    borderColor: `${theme.palette.common.white} !important`,
    borderRadius: 6,
  },
  imageDefault: {
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
    '& > p': {
      fontSize: '.75rem',
      fontWeight: 500,
    },
  },
  imageSettings: {
    position: 'absolute',
    top: theme.spacing(-2),
    right: theme.spacing(-2),
  },
  dialogContent: {
    marginTop: theme.spacing(-4.5),
  },
  checkboxWrapper: {
    margin: 16,
    '&.checkboxWrapperSx': {
      margin: '12px 16px',
    },
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  checked: {
    '& .Mui-checked + .MuiTypography-root': {
      textDecoration: 'line-through',
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiSvgIcon-root': {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
  },
  radio: {
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  textAreaClear: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  nativeSelect: {
    width: '100%',
    padding: theme.spacing(1, 2),
    paddingRight: theme.spacing(4.5),
    minHeight: 26,
    lineHeight: '26px',
    borderRadius: 6,
    border: 'none',
    backgroundColor: theme.palette.secondary.light,
    borderColor: `${theme.palette.common.white}`,
    boxSizing: 'border-box',
    appearance: 'none',
    backgroundImage: `linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%)`,
    backgroundPosition: `calc(100% - 20px) calc(1em + 5px),
            calc(100% - 15px) calc(1em + 5px),
            calc(100% - 2.5em) 0.5em`,
    backgroundSize: `5px 5px,
            5px 5px,
            1px 1.5em`,
    backgroundRepeat: 'no-repeat',
    '& option': {
      padding: theme.spacing(0.5, 1),
      marginBottom: theme.spacing(0.5),
      borderRadius: 4,
    },
  },
  closeIcon: {
    color: theme.palette.grey[400],
  },
  disabled: {
    '& p': {
      color: theme.palette.grey[500],
    },
    '& input': {
      color: `${theme.palette.grey[500]}!important`,
    },
    '& div[type="text"]': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));
